<template>
  <div class="flex w-full">
    <div class="w-full" :class="{ 'xl:w-2/3': developer_tools }">
      <div class="grid grid-cols-2 ml-5 mt-10 text-xl font-large text-gray-700">
        <div>Retrieve a Meter</div>
        <div class="flex justify-end hover:cursor-pointer">
          <ChevronDoubleRightIcon
            v-if="developer_tools"
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
            @click="developer_tools = false"
          />
          <ChevronDoubleLeftIcon
            v-else
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
            @click="developer_tools = true"
          />
        </div>
      </div>
      <div class="flex">
        <div class="ml-4 text-sm font-sm text-gray-500">
          <button class="bg-green-200 rounded-full text-xs font-xs px-2 mx-1">
            GET</button
          >https://api.coreclimate.io/v1/meters/_id
        </div>
      </div>
      <div>
        <hr class="mt-2 mb-5 mx-5" />
      </div>

      <div>
        <div class="ml-5 mt-10 text-sm font-small text-gray-500"></div>
      </div>

      <form class="mb-5">
        <div class="ml-5 mr-5">
          <div>
            <h2 class="text-sm text-gray-900">PATH PARAMS</h2>

            <div
              class="mt-5 space-y-2 border border-gray-200 pb-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:pb-0 bg-gray-100 rounded-lg p-2 mb-5"
            >
              <div
                class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2"
              >
                <div>
                  <div class="flex">
                    <span
                      class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 pr-2"
                      >_id</span
                    ><span
                      class="block text-sm font-sm leading-6 text-gray-500 sm:pt-1.5"
                      >string</span
                    >
                  </div>

                  <div class="text-sm font-sm text-gray-600">
                    meter object id
                  </div>
                </div>

                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div
                    class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"
                  >
                    <input
                      type="text"
                      class="block flex-1 border-1 rounded border-gray-200 py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 bg-white"
                      v-model="meter_id"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex border-0 justify-end">
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                @click="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>

      <div>
        <div class="grid grid-cols-1 md:grid-cols-2 m-5 rounded-lg">
          <div class="bg-gray-50 p-5 pl-10 shadow">
            <div class="text-sm font-large text-gray-900">Meter Number</div>
            <div class="text-xl font-small text-gray-900">
              {{ meter.meter_number }}
            </div>
            <div>
              <img
                class="object-cover w-full h-full rounded-lg mt-5 p-5"
                :src="meter.imageUrl"
                alt="meter image"
              />
            </div>
          </div>
          <div class="p-5">
            <div>
              <div class="px-4 sm:px-0">
                <h3 class="text-base font-semibold leading-7 text-gray-900">
                  Meter Details
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                  Detailed information about the Meter
                </p>
              </div>
              <div class="mt-6 border-t border-gray-100">
                <dl class="divide-y divide-gray-100">
                  <div
                    class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                      Customer
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                    >
                      {{ meter.customer_name }}
                    </dd>
                  </div>
                  <div
                    class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                      Address
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                    >
                      {{ meter.address }}
                    </dd>
                  </div>
                  <div
                    class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                      Description
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                    >
                      {{ meter.description }}
                    </dd>
                  </div>
                  <div
                    class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                      Supplier Name
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                    >
                      {{ meter.supplier_name }}
                    </dd>
                  </div>
                  <div
                    class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                      Status
                    </dt>
                    <dt v-if="!editing_status">
                      <span
                        class="items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                        :class="{
                          'bg-green-50 text-green-700 ring-green-600/20':
                            meter.status === 'active',
                          'bg-red-50 text-red-700 ring-red-600/20':
                            meter.status === 'inactive',
                          'bg-blue-50 text-blue-700 ring-blue-600/20':
                            meter.status === 'closed',
                        }"
                        >{{ meter.status }}</span
                      >
                    </dt>
                    <dt v-else>
                      <div>
                        <select
                          v-model="meter.status"
                          class="mt-1 px-10 pl-5 py-1 text-sm text-gray-500 border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                          <option>active</option>
                          <option>inactive</option>
                          <option>closed</option>
                        </select>
                      </div>
                    </dt>
                    <dt>
                      <div class="flex">
                        <button
                          type="button"
                          v-if="!editing_status"
                          class="inline-flex items-center px-3 py-1 border border-transparent text-xs leading-4 font-xs rounded-md text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                          @click="editing_status = true"
                        >
                          <PencilIcon
                            class="-ml-1 mr-1.5 h-5 w-5 text-gray-400"
                          />
                          edit
                        </button>
                      </div>
                      <div class="flex">
                        <div class="flex">
                          <button
                            type="button"
                            v-if="editing_status"
                            class="inline-flex items-center m-1 px-3 py-1 border border-transparent text-xs leading-4 font-xs rounded-md text-gray-500 bg-gray-100 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                            @click="editing_status = false"
                          >
                            cancel
                          </button>
                        </div>
                        <div class="flex">
                          <button
                            type="button"
                            v-if="editing_status"
                            class="inline-flex items-center m-1 px-3 py-1 border border-transparent text-xs leading-4 font-xs rounded-md text-gray-500 bg-gray-100 hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                            @click="(editing_status = false), updateMeter()"
                          >
                            save
                          </button>
                        </div>
                      </div>
                    </dt>
                  </div>
                  <div
                    class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                      Last Reading
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                    >
                      {{ formatDate(meter.last_consumption_date) }}
                    </dd>
                  </div>
                  <div
                    class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                      Last Scanned
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                    >
                      {{ formatDate(meter.last_scanned_date) }}
                    </dd>
                  </div>
                  <div
                    class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt class="text-sm font-medium leading-6 text-gray-900">
                      Notes
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                    >
                      <textarea
                        v-model="new_note"
                        class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        rows="3"
                      ></textarea>
                      <div class="pt-2 flex justify-end">
                        <button
                          type="button"
                          class="inline-flex items-center px-2 py-1 text-xs font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          @click="addNote"
                        >
                          <PlusIcon
                            class="-ml-1 mr-1.5 h-5 w-5 text-gray-400"
                          />
                          <span>Add Note</span>
                        </button>
                      </div>

                      <div
                        class="flex pt-4"
                        v-for="note in meter.notes"
                        :key="note.author"
                      >
                        <div class="flex-none max-width-min">
                          <PaperClipIcon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div class="grow">
                          <div class="text-xs font-medium pl-2">
                            {{ note.content }}
                          </div>
                          <div class="text-xs font-xs pl-2">
                            {{ formatDate(note.created_at) }} by
                            {{ note.author }}
                          </div>
                        </div>
                        <div class="flex-none justify-end">
                          <TrashIcon
                            class="-ml-1 mr-1.5 h-5 w-5 text-gray-200 hover:text-red-400"
                            v-on:click="deleteNote(note._id)"
                          />
                        </div>
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="filter shadow mt-10 mb-2 border rounded-md h-96">
        <div class="px-4 py-4 sm:px-6 lg:px-8 bg-gray-50">
          <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
              <div class="mt-0 text-sm font-semibold leading-6 text-gray-900">
                Statements for {{ meter.meter_number }}
              </div>
            </div>
          </div>
          <div class="mt-8 flow-root bg-white p-5 border-0 rounded">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div
                class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 h-64 overflow-auto"
              >
                <table class="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Start Date
                      </th>
                      <th
                        scope="col"
                        class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        End Date
                      </th>
                      <th
                        scope="col"
                        class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Days
                      </th>
                      <th
                        scope="col"
                        class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Audit Tracker
                      </th>
                      <th
                        scope="col"
                        class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        kWh
                      </th>
                      <th
                        scope="col"
                        class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Download
                      </th>
                    </tr>
                  </thead>

                  <tbody class="divide-y divide-gray-200">
                    <tr v-for="statement in statements" :key="statement">
                      <td
                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                      >
                        {{ formatDate(statement.start_date) }}
                      </td>
                      <td
                        class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                      >
                        {{ formatDate(statement.end_date) }}
                      </td>
                      <td
                        class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                      >
                        {{
                          (new Date(statement.end_date) -
                            new Date(statement.start_date)) /
                          (24 * 60 * 60 * 1000)
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                      >
                        {{ statement.audit_tracking }}
                      </td>
                      <td
                        class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                      >
                        <div v-if="statement.total_consumption">
                          {{ statement.total_consumption.toLocaleString() }}
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-0"
                      >
                        <DocumentDownloadIcon
                          class="flex-shrink-0 h-7 w-7 ml-5 text-gray-400"
                          v-on:click="download(statement._id)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-20">
        <button
          type="button"
          class="m-5 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          @click="getMonthly"
        >
          Monthly
        </button>
        <button
          type="button"
          class="m-5 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          @click="getYearly"
        >
          Yearly
        </button>
      </div>
      <div v-if="chart">
        <div class="filter shadow mt-2 mb-2 border bg-white rounded-md">
          <apexchart
            width="100%"
            height="300"
            :options="chart.options"
            :series="chart.series"
          ></apexchart>
        </div>
      </div>
    </div>
    <aside
      class="mt-28 m-5 w-1/3 bg-white hidden xl:block border rounded-lg font-mono text-xs bg-gray-50"
      v-if="developer_tools"
    >
      <div v-for="response in responses" :key="response">
        <div class="bg-gray-200 text-sm p-2">Request</div>
        <div class="ml-2 mt-2 mb-2 whitespace-pre overflow-x-auto">
          {{ JSON.stringify(response.config, null, 2) }}
        </div>
        <div class="bg-gray-200 text-sm p-2">Response</div>
        <div class="ml-2 mt-2 mb-2 whitespace-pre overflow-x-auto">
          {{ JSON.stringify(response.data, null, 2) }}
        </div>
        <hr />
      </div>
    </aside>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import ServerResponse from '../components/ServerResponse';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import {
  ChevronDownIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChartBarIcon,
} from '@heroicons/vue/solid';
import {
  PaperClipIcon,
  TrashIcon,
  DocumentDownloadIcon,
} from '@heroicons/vue/outline';
import MoveDevice from '../components/MoveDevice.vue';
import {
  MailIcon,
  LightningBoltIcon,
  PlusIcon,
  PencilIcon,
} from '@heroicons/vue/solid';
import moment from 'moment';

const people = [
  {
    name: '1200028842352',
    title: '99 Sloane Street, LONDON, SW1X 0BJ ,UK',
    role: 'Admin',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://maps.googleapis.com/maps/api/streetview?size=256x256&location=99%20+Cadogan+Gardens,LONDON,SW3+2RF,UK&pitch=20&key=AIzaSyBYinweZPhhopfi2ATYK7KhMigtAI4YbAI',
  },
  // More people...
];
export default {
  setup() {
    return {
      people,
    };
  },

  components: {
    ServerResponse,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ChartBarIcon,
    MoveDevice,
    MailIcon,
    LightningBoltIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    PaperClipIcon,
    PlusIcon,
    TrashIcon,
    PencilIcon,
    DocumentDownloadIcon,
  },

  data() {
    return {
      chart: null,
      series_data: [],
      dateMath,
      serverResponse: '',
      latestSearchString: '',
      account_tag_groups: {},
      search_string: '',
      search_tags: [],
      move_meter_checked: false,
      requestMoveDevice: false,
      utility_map: new Map(),
      responses: [],
      api_key: '',
      page: 1,
      page_size: 20,
      meter_number: '',
      meter_id: '',
      statements: [],
      has_more: true,
      has_less: true,
      developer_tools: false,
      meter: {},
      show_add_note: false,
      new_note: '',
      editing_status: false,
    };
  },
  methods: {
    download(statement_id) {
      axios({
        method: 'get',
        url: '/pdfs/download?statement_id=' + statement_id,
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.api_key,
        },
        responseType: 'arraybuffer',
      }).then((response) => {
        let blob = new Blob([response.data], {
            type: 'application/pdf',
            headers: ['content-type'],
          }),
          url = window.URL.createObjectURL(blob);

        window.open(url); // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
      });
    },
    getYearly() {
      this.loadDailyReportsForMeter(this.meter_id, 'year');
    },
    getMonthly() {
      this.loadDailyReportsForMeter(this.meter_id, 'month');
    },
    formattedResponse(response) {
      return JSON.stringify(response, null, 2);
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YY');
      }
    },
    submit() {
      this.makeHTTPRequest('/meters/' + this.meter_id);
    },
    updateMeter() {
      let url = '/meters/' + this.meter_id;
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.api_key,
        },
      };

      axios.put(url, this.meter, config).then((res) => {
        this.responses = [];
        this.responses.push(res);
      });
    },
    addNote() {
      this.meter.notes.push({
        content: this.new_note,
        author: this.$auth.user.value.name,
        created_at: new Date(),
      });

      //let's sort the notes by date
      this.meter.notes.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      let url = '/meters/' + this.meter_id;
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.api_key,
        },
      };

      axios.put(url, this.meter, config).then((res) => {
        this.new_note = '';
      });
    },
    deleteNote(note_id) {
      this.meter.notes = this.meter.notes.filter((note) => {
        return note._id != note_id;
      });
      console.log(this.meter.notes);

      let url = '/meters/' + this.meter_id;
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.api_key,
        },
      };

      axios.put(url, this.meter, config).then((res) => {
        this.responses = [];
        this.responses.push(res);
      });
    },

    makeHTTPRequest(url) {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.api_key,
        },
      };
      axios.get(url, config).then((res) => {
        let url = res.data.data.address.replace(/\s+/g, '+');
        res.data.data.imageUrl = `https://maps.googleapis.com/maps/api/streetview?size=256x256&location=${url}&pitch=20&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`;
        console.log(res.data.data);
        this.meter = res.data.data;
        this.serverResponse = JSON.stringify(res, null, 2);
        this.responses = [];
        this.responses.push(res);
        this.loadStatementsForMeter(this.meter._id);
      });
    },
    loadStatementsForMeter(meter_id) {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.api_key,
        },
      };
      let statements_url = '/meters/' + meter_id + '/statements';
      axios.get(statements_url, config).then((res) => {
        this.statements = res.data.data;
        this.responses = [];
        this.responses.push(res);
        this.loadDailyReportsForMeter(this.meter._id);
      });
    },
    loadDailyReportsForMeter(meter_id, interval = 'month') {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.api_key,
        },
      };
      let daily_reports_url =
        '/daily_reports?interval=' + interval + '&meter_id=' + meter_id;
      axios.get(daily_reports_url, config).then((res) => {
        this.daily_reports = res.data.data;
        let data = [];
        for (let report of this.daily_reports) {
          data.push({
            x: report.date,
            y: report.consumption,
          });
        }
        //this.responses = [];
        //this.responses.push(res);
        this.series_data = [
          {
            name: 'Consumption',
            data: data,
          },
        ];
        console.log(this.series_data);
        this.loadGraph();
      });
    },

    loadPage() {
      const currentAccountSession = this.$store.state.clime_account.name;
      const currentProjectSession = this.$store.state.clime_project.name;

      //let's reset the params
      this.page = 1;
      this.page_size = 20;

      console.log(
        'this.$store.state.clime_account.name: ' +
          this.$store.state.clime_account.name
      );

      axios
        .get(`/core_accounts/${this.$store.state.clime_account.id}`)
        .then((res) => {
          //Let's get the API key for this account before we do anything else
          this.api_key = res.data.data.api_key;
          console.log(
            'making request for meters: ' + '[/meters/' + this.meter_id + ']'
          );
          this.makeHTTPRequest('/meters/' + this.meter_id);
        });
    },
    loadGraph() {
      // console.log('loadGraph() series_data:');
      // console.log(this.series_data);
      let series_type = this.meter.meter_type;
      this.chart = {
        reference_number: 'reference_number',
        options: {
          theme: {
            palette: 'palette2',
          },

          chart: {
            stacked: false,
            type: 'bar',
            height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: 'zoom',
            },
          },
          stroke: {
            show: true,
            curve: ['smooth'],
            lineCap: 'butt',
            colors: undefined,
            width: 2,
            dashArray: 0,
          },
          title: {
            text: this.meter.meter_number,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238',
            },
          },
          subtitle: {
            text: this.meter.meter_number,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 20,
            floating: false,
            style: {
              fontSize: '12px',
              fontWeight: 'normal',
              fontFamily: undefined,
              color: '#9699a2',
            },
          },
          dataLabels: {
            enabled: false,
          },

          tooltip: {
            enabled: true,
            x: {
              show: true,
              format: 'MM yyyy',
            },
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            autoSelected: 'zoom',
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: true,
              format: 'MM yy',
            },
          },
          yaxis: [
            {
              seriesName: 'electricity',
              show: true,
              title: {
                text: 'kwh',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
              },
              labels: {
                formatter: function (value) {
                  return value;
                },
              },
            },
          ],
        },
        series: this.series_data,
      };
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.clime_account.id,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.clime_project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    console.log('Meters created called!!');
    this.meter_id = this.$route.params.id;
    console.log('Setting meter_id to: ' + this.meter_id);
    this.loadPage();
  },
};
</script>
